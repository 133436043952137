import FileSaver from 'file-saver'

export const getFilenameFromHeader = (response) => {
    const contentDisposition = response.headers?.['content-disposition'] || ""
    // content disposition has this form: "attachment; filename=\"<...>\""
    const fileName = (contentDisposition.split(';')[1])?.trim() || ""
    // now filename is "filename=\"<...>\""
    const splitedFileName = fileName.split('=')[1] || ""

    return splitedFileName.replace(/"/g, '') || ``
}

export const saveFile = (csvData, name, options = { type: 'text/csv;charset=utf-8;' }) => FileSaver.saveAs(new Blob([csvData], options), name)