import React, { useState, useCallback, useEffect } from "react";
import { Grid, Input, Dropdown, Popup, Icon } from "semantic-ui-react";
import { addressAutocomplete } from "./apicalls.js";
import './App.css'

const Egon = ({ objSend, setObjSend, keysMap, objData }) => {

  const [data, setData] = useState({});
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [cap, setCap] = useState("");
  const [civico, setCivico] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [unlock, setUnlock] = useState(true);

  const onCitySearchChange = async (event) => {
    const input = event.target.value;
    const resp = await addressAutocomplete(data, input, "city");
    const temp = [];
    for (let i = 0; i < resp.length; i++) {
      temp.push({
        key: i,
        text: resp[i].c,
        value: resp[i].c,
      });
    }
    setCities(temp);
  };

  const onCityChange = async (event, element) => {
    setCity(element.value);
    setCities([
      {
        key: element.value,
        text: element.value,
        value: element.value,
      },
    ]);
    const resp = await addressAutocomplete(data, element.value, "city");
    const temp = [];
    for (let i = 0; i < resp.length; i++) {
      temp.push({
        key: i,
        text: resp[i].p,
        value: resp[i].p,
      });
    }
    setProvinces(temp);
    setProvince(resp[0].p);
    //setProvince_acr(resp[0].x)
    setData({
      ...data,
      ...{ city: element.value, province: resp[0].p, province_acr: resp[0].x },
    });

    setObjSend({
      ...objSend,
      ...{ [keysMap.citta]: element.value, [keysMap.pr]: resp[0].x },
    });

    setAddress("");
    setAddresses([]);
    setCap("");
  };

  const onProvinceSearchChange = async (event) => {
    const input = event.target.value;
    const resp = await addressAutocomplete(data, input, "province");
    const temp = [];
    for (let i = 0; i < resp.length; i++) {
      temp.push({
        key: i,
        text: resp[i].p,
        value: resp[i].p,
      });
    }
    setProvinces(temp);
  };

  const onCivicChange = (event) => {
    const input = event.target.value;
    setCivico(input);
    setObjSend({ ...objSend, ...{ [keysMap.civico]: input } });
  };

  const onProvinceChange = (event, element) => {
    if (element.value !== "") {
      setProvince(element.value);
      setObjSend({ ...objSend, [keysMap.province]: element.value });
      setData({ ...data, ...{ province: element.value } });
    }
    setCity("");
    setCities([]);
    setAddress("");
    setAddresses([]);
    setCap("");
  };

  const onAddressSearchChange = async (event) => {
    const input = event.target.value;
    const resp = await addressAutocomplete(data, input, "address");
    const temp = [];
    for (let i = 0; i < resp.length; i++) {
      temp.push({
        key: i,
        text: resp[i].s,
        value: resp[i].s,
      });
    }
    setAddresses(temp);
  };

  const onAddressChange = async (event, element) => {
    setAddress(element.value);
    const resp = await addressAutocomplete(data, element.value, "address");
    setCap(resp[0].z);
    setData({ ...data, ...{ address: element.value, cap: resp[0].z } });
    setObjSend({ ...objSend, ...{ [keysMap.indir]: element.value, [keysMap.cap]: resp[0].z } });
  };

  const onCapChange = async (event) => {
    if (event.target.value <= 99999) {
      setCap(event.target.value);
      setData({ ...data, ...{ cap: event.target.value } });
      setObjSend({ ...objSend, ...{ [keysMap.cap]: event.target.value } });
    }
  };

  const onUnlockClick = useCallback((boolean) => {
    setUnlock(boolean);
    setObjSend((prevState) => {
      let cleanObj = {
        [keysMap.citta]: "",
        [keysMap.pr]: "",
        [keysMap.indir]: "",
        [keysMap.cap]: "",
        [keysMap.civico]: "",
      }

      return {
        ...prevState, ...cleanObj
      }
    })

  }, [keysMap, setObjSend])

  const onInputChange = useCallback(
    (event, par) => {
      if (par === "citta") {
        setCity(event.target.value)
      } else if (par === "pr") {
        setProvince(event.target.value)
      } else if (par === "indir") {
        setAddress(event.target.value)
      }
      setObjSend({ ...objSend, ...{ [keysMap[par]]: event.target.value } });
    },
    [objSend, setObjSend, keysMap]
  );

  return (
    <React.Fragment>

      <Grid.Column width={3}>
        <p>Città *</p>
        {(unlock && (
          <Input
            fluid
            value={city}
            type="string"
            onChange={(event) => onInputChange(event, "citta")}
            placeholder="Inserisci la città"
          />
        )) || (
            <Dropdown
              placeholder="Inserisci la città"
              fluid
              search
              selection
              value={city}
              options={cities}
              onSearchChange={onCitySearchChange}
              onChange={onCityChange}
            />
          )}
      </Grid.Column>
      <Grid.Column width={3}>
        <p>Provincia *
          <Popup
            trigger={
              <Icon
                size="tiny"
                name="info"
                inverted
                circular
                color="green"
                style={{
                  cursor: "pointer",
                  float: "right"
                }}
              />
            }
            content="Inserire la provincia con la singla in maiscolo es. MI (Nel caso di stradario attivo invece verrà inserita per esteso)"

          />
        </p>

        {(unlock && (
          <Input
            fluid
            value={province}
            type="string"
            onChange={(event) => onInputChange(event, "pr")}
            placeholder="Es. MI"
          />
        )) || (
            <Dropdown
              placeholder="Es. MI"
              fluid
              search
              selection
              value={province}
              options={provinces}
              onSearchChange={onProvinceSearchChange}
              onChange={onProvinceChange}
            />
          )}
      </Grid.Column>
      <Grid.Column width={4}>
        <p>Via *</p>
        {(unlock && (
          <Input
            fluid
            value={address}
            type="string"
            onChange={(event) => onInputChange(event, "indir")}
            placeholder="Inserisci la via"
          />
        )) || (
            <Dropdown
              placeholder="Inserisci la via"
              fluid
              search
              selection
              value={address}
              options={addresses}
              onSearchChange={onAddressSearchChange}
              onChange={onAddressChange}
            />
          )}
      </Grid.Column>

      <Grid.Column width={3}>
        <p>Civico *</p>
        <Input
          fluid
          value={civico}
          type="number"
          max="99999"
          onChange={onCivicChange}
          placeholder="Inserisci il civico"
        />
      </Grid.Column>

      <Grid.Column width={3}>
        <p>Cap *</p>

        <Input
          value={cap}
          type="number"
          fluid
          max="99999"
          onChange={onCapChange}
          placeholder="CAP"
        />
      </Grid.Column>

      <Grid.Column width={13}></Grid.Column>

      <Grid.Column width={3}>
        <Popup
          trigger={
            <button
              onClick={() => {
                onUnlockClick(!unlock);
                setData([])
                if (!unlock) {
                  setCity("");
                  setAddress("");
                  setProvince("");
                  setCivico("");
                  setCap("");
                }
              }}
              className="background-image"
            >
              <i className={`icon ${unlock ? "power off red large" : "power off green large"}`} />
            </button>
          }
          content={unlock ? "STRADARIO DISATTIVATO, Clicca per attivarlo" : "STRADARIO ATTIVO, Clicca per disattivarlo"}
        />
      </Grid.Column>
    </React.Fragment>
  );
};

export default Egon;
