import axios from "axios";
import { getFilenameFromHeader } from "./services/fileSaver";

export const APIgetRetriveLogistictsProducts = async (cluster, id) => {
  let url = `/api/logistics/${cluster}/products/${id}/stock`;

  const response = await axios
    .get(url, {}, {})
    .catch((error) => console.error(error.response.statusText));

  return response ? response.data : [];
};

export const APIgetRetriveWarehouse = async (cluster) => {
  let url = `/api/logistics/${cluster}/warehouse`;

  const response = await axios
    .get(url, {}, {})
    .catch((error) => console.error(error.response.statusText));

  return response ? response.data : [];
};

export const APIgetRetriveSingleWarehouse = async (cluster, codice) => {
  let url = `/api/logistics/${cluster}/warehouse/${codice}`;

  const response = await axios
    .get(url, {}, {})
    .catch((error) => console.error(error.response.statusText));

  return response ? response.data : [];
};

export const APIpostOrdersLogistics = async (cluster, id, body) => {
  let url = `/api/logistics/${cluster}/orders/${id}/new`;

  const response = await axios.post(url, body, {}).catch((error) => {
    return error.response;
  });

  return response || {};
};

export const APIpostSearchProducsFilesLogistics = async (cluster, body, format = 'json') => {
  let url = `/api/logistics/${cluster}/files/search/products`;

  try {
    const response = await axios.post(url, { ...body, format }, {})

    if (format === 'csv') {
      return {
        data: response.data,
        name: getFilenameFromHeader(response)
      }
    } else {
      return {
        data: response.data
      }
    }

  } catch (error) {
    if (format === "csv") {
      return { data: '', name: '' }
    } else {
      return { data: [] }
    }
  }

};

export const APIpostSearchOrdersFilesLogistics = async (cluster, body, format = 'json') => {
  let url = `/api/logistics/${cluster}/files/search/orders`;

  try {
    const response = await axios.post(url, { ...body, format }, {})
    if (format === 'csv') {
      return {
        data: response.data,
        name: getFilenameFromHeader(response)
      }
    } else {
      return {
        data: response.data
      }
    }
  } catch (error) {
    if (format === "csv") {
      return { data: '', name: '' }
    } else {
      return { data: [] }
    }
  }

};

export const APIpostReturnLogistics = async (cluster, idWorkspace, body) => {
  let url = `/api/logistics/${cluster}/returns/${idWorkspace}/new`;

  const response = await axios.post(url, body, {}).catch((error) => {
    return error.response;
  });

  return response || {};
};

export const addressAutocomplete = async (inputs, input, name) => {
  // campi raccolti / valore campo ricerca selezionato / (address - province - city)
  let resp;
  let p = "";
  let c = "";
  let s = "";

  if (input !== "") {
    if (name === "province") {
      p = encodeURIComponent(input);
      resp = await axios.get("/api/cntmng/utils/address/provinces?p=" + p);

      return resp.data;
    } else if (name === "city") {
      if (inputs) {
        p = encodeURIComponent(inputs.province);
      } else {
        p = "nan";
      }
      c = encodeURIComponent(input);
      resp = await axios.get(
        "/api/cntmng/utils/address/cities?p=" + p + "&c=" + c
      );

      return resp.data;
    } else if (name === "address") {
      p = encodeURIComponent(inputs.province);
      c = encodeURIComponent(inputs.city);
      s = encodeURIComponent(input);
      resp = await axios.get(
        "/api/cntmng/utils/address/streets?p=" + p + "&c=" + c + "&s=" + s
      );

      return resp.data;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const APIpostRetriveArrayContactList = async (
  workspaceId,
  key,
  file,
  setMsg,
  setColorMsg
) => {
  const formData = new FormData();
  formData.append("fileContent", file);

  const response = await axios
    .post(`/api/cntmng/init/${key}/${workspaceId}`, formData, {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    })
    .catch((error) => {
      setMsg(error.response.statusText);
      setColorMsg('red')
      console.error(error.response.statusText);
    });

  if (response) {
    setMsg("Success");
    setColorMsg('green')
  }

  return response ? response.data : [];
};

export const APIgetReturnUploadTemplate = async (cluster, key) => {
  try {
    const response = await axios
      .get(`/api/logistics/${cluster}/templates/returns/${key}`, { withCredentials: true, headers: { "Content-Type": "application/json" } })

    return {
      data: response.data,
      name: getFilenameFromHeader(response)
    }
  } catch (error) {
    console.error(error.response);
    return {}
  }
}

export const APIpostRetriveServicePoint = async (body) => {
  let path = '/api/sisal/servicePoints'
  return axios.post(path, body, { withCredentials: true, headers: { "Content-Type": "application/json" } })
}

export const APIpostCreateRequest = async (body) => {
  let path = '/api/sisal/createRequest'
  return axios.post(path, body, { withCredentials: true, headers: { "Content-Type": "application/json" } })
}

export const APIgetRetriveWharehouse = async () => {
  let url = '/api/sisal/warehouse'
  return await axios.get(url, { withCredentials: true, headers: { "Content-Type": "application/json" } })
}

export const APIgetUserAssociation = async () => {

  let path = "/api/sisal/info/userFeAssociation/"

  return await axios.get(path, { withCredentials: true, headers: { "Content-Type": "application/json" } }) 

}