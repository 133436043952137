import React from "react";

const HandlerUser = () => {


    return <React.Fragment>
      HandlerUser
    </React.Fragment>
}

export default HandlerUser
