import "./index.js";
import "./App.css";
import React from "react";
import CustomPages from "./CustomPages.js";

function App() {

  return (
    <React.Fragment>
      <CustomPages />
    </React.Fragment>
  );
}

export default App;
