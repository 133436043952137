import React from "react";
import CreateOrder from "./CreateOrder";
import HandlerStock from "./HandlerStock";
import HandlerUser from "./HandlerUser";

const CustomPages = ({ workspace, pageConfiguration, i18n, dataModel, onSuccess, onError }) => {

  const pageComponents = React.useMemo(() => ({
    CreateOrder,
    HandlerStock,
    HandlerUser
  }), [])

  const Comp = React.useMemo(() => pageComponents[pageConfiguration.externalComponent], [pageComponents, pageConfiguration.externalComponent])
  return Comp ? <Comp workspace={workspace} pageConfiguration={pageConfiguration} i18n={i18n} dataModel={dataModel} onSuccess={onSuccess} onError={onError} /> : null

};

export default CustomPages;
