import React from "react";

const HandlerStock = () => {


    return <React.Fragment>
        HandlerStock
    </React.Fragment>
}

export default HandlerStock
