import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Button, Grid, Segment, Select, Input, Popup, TextArea } from "semantic-ui-react";
import Egon from "./Egon";
import { APIpostRetriveServicePoint, APIpostCreateRequest, APIgetRetriveWharehouse, APIgetUserAssociation } from "./apicalls";

const CreateOrder = (props) => {

    const [objNewOrder, setObjNewOrder] = useState({
        "nazioneDestinazione": "IT",
        "nazioneRitiro": "IT"

    })
    const [btnButtonPudoDisabled, setBtnPudoDisabled] = useState(true)
    const [dataBtnPudo, setDataBtnPudo] = useState([])
    const [indexPudoSelected, setIndexPudoSelected] = useState(null)
    const valuesDay = [
        {
            text: "Nessun valore",
            value: "",
            key: ""
        },
        {
            text: "Lunedì",
            value: "MON",
            key: "MON"
        },
        {
            text: "Martedì",
            value: "TUE",
            key: "TUE"
        },
        {
            text: "Mercoledì",
            value: "WED",
            key: "WED"
        },
        {
            text: "Giovedì",
            value: "THU",
            key: "THU"
        },
        {
            text: "Venerdì",
            value: "FRI",
            key: "FRI"
        },
        {
            text: "Sabato",
            value: "SAT",
            key: "SAT"
        },
        {
            text: "Domenica",
            value: "SUN",
            key: "SUN"
        }
    ]

    const valuesNation = [
        {
            text: "ITALIA",
            value: "IT",
            key: "am"
        },
    ]

    const periodDay = [
        {
            text: "Nessun valore",
            value: "",
            key: ""
        },
        {
            text: "AM",
            value: "AM",
            key: "am"
        },
        {
            text: "PM",
            value: "PM",
            key: "pm"
        },
    ]

    const mapLabelPudoSelected = {
        "ragSociale": "Ragione Sociale",
        "code": "Codice",
        "address": "Indirizzo",
        "city": "Città",
        "cap": "CAP"
    }

    const [dataSelectWarehouse, setDataSelectWarehouse] = useState([])
    const [dataWarehouse, setDataWarehouse] = useState([])
    const [warehouseName, setWarehouseName] = useState("")
    const [dataUser, setDataUser] = useState(null)

    const loadWharehouse = useCallback(async () => {

        if (dataWarehouse.length === 0) {
            let { data } = await APIgetRetriveWharehouse()
            setDataWarehouse(data)
            setDataSelectWarehouse(data.map(singleWarehouse => {
                return {
                    text: singleWarehouse.nomeMagazzino,
                    value: singleWarehouse.nomeMagazzino,
                    key: singleWarehouse.nomeMagazzino
                }
            }))
        }
    }, [dataWarehouse])

    const invia = useCallback(async () => {

        let objPudos = {
            indirizzoRitiro_PUDO: dataBtnPudo?.[indexPudoSelected]?.address,
            cittaRitiro_PUDO: dataBtnPudo?.[indexPudoSelected]?.city,
            capRitiro_PUDO: dataBtnPudo?.[indexPudoSelected]?.cap,
            ragioneSocialeRitiro_PUDO: dataBtnPudo?.[indexPudoSelected]?.ragSociale,
            codice_PUDO: dataBtnPudo?.[indexPudoSelected]?.code,
        }
        let obj = { ...objNewOrder, ...objPudos }
        try {
            const response = await APIpostCreateRequest(obj)
            if (response) {
                props.onSuccess(response.statusText)
                console.log(response);

                if (response.data.pdf) {
                    var binaryPdf = atob(response.data.pdf);
                    var blob = new Blob([new Uint8Array(Array.prototype.map.call(binaryPdf, function (c) {
                        return c.charCodeAt(0);
                    }))], { type: 'application/pdf' });

                    // Create a URL object for the Blob
                    var url = URL.createObjectURL(blob);
                    window.open(url, '_blank');
                }
                setTimeout(() => {
                    window.location.reload()
                }, 1000)

            }
        } catch (err) {
            props.onError(err)

        }

    }, [dataBtnPudo, indexPudoSelected, objNewOrder, props])

    useLayoutEffect(() => {
        async function getData() {
            try {
                const { data } = await APIgetUserAssociation()
                if (data) setDataUser(data)
            }
            catch (error) {
                console.log(error);
            }


        }
        getData()

        //clean up
        return () => {
            setDataUser(null)
        }

    }, [])

    useEffect(() => {
        let arrCheck = []
        let checkKey = ["cittaRitiro", "provinciaRitiro", "capRitiro"]

        Object.entries(objNewOrder).map(x => {
            if (checkKey.includes(x[0]) && x[1].length > 0) {
                arrCheck.push(x[0])
            } else if (checkKey.includes(x[0]) && x[1].length === 0) {
                arrCheck.filter(elemento => elemento !== x[0])
            }
            return x
        })

        if (arrCheck.length === checkKey.length) {
            setBtnPudoDisabled(false)
        } else {
            setBtnPudoDisabled(true)
        }

    }, [objNewOrder])

    return <div style={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
        <Grid centered style={{ width: "70%", marginTop: '20px', padding: "10px" }}>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment color="pink">
                        <h5 style={{ textAlign: "center" }}>DATI RICHIEDENTE</h5>
                        <Grid centered>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <p>Codice FE</p>
                                    <Input
                                        fluid
                                        disabled
                                        value={dataUser?.feCode || ""}
                                    />
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <p>FE</p>
                                    <Input
                                        fluid
                                        disabled
                                        value={dataUser?.fe || ""}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <p>Area</p>
                                    <Input
                                        fluid
                                        disabled
                                        value={dataUser?.area || ""}
                                    />
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <p>Regione</p>
                                    <Input
                                        fluid
                                        disabled
                                        value={dataUser?.region || ""}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <p>Nazione *</p>
                                    <Select
                                        options={valuesNation}
                                        value="IT"
                                        fluid
                                    />
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <p>Telefono *</p>
                                    <Input
                                        fluid
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "telefonoRitiro": event.target.value }
                                                }
                                            })
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <p>Email *</p>
                                    <Input
                                        fluid
                                        autoComplete="off"
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "emailRitiro": event.target.value }
                                                }
                                            })
                                        }}
                                    />
                                </Grid.Column>

                            </Grid.Row>

                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16}>

                    <Segment color="pink">
                        <h5 style={{ textAlign: "center" }}>
                            INDIRIZZO RICHIEDENTE
                        </h5>
                        <Grid centered>
                            <Grid.Row>
                                <Egon
                                    setObjSend={setObjNewOrder}
                                    objSend={objNewOrder}
                                    keysMap={{
                                        "citta": "cittaRitiro",
                                        "pr": "provinciaRitiro",
                                        "indir": "viaRitiro",
                                        "cap": "capRitiro",
                                        "civico": "civicoRitiro"
                                    }}
                                />
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <p>Data Ritiro *</p>
                                    <Input
                                        type="date"
                                        fluid
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "dataRitiro": event.target.value }
                                                }
                                            })
                                        }}
                                    />
                                </Grid.Column>

                                <Grid.Column width={3}>
                                    <p>Ora Ritiro *</p>
                                    <Input
                                        type="time"
                                        fluid
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "oraRitiro": event.target.value }
                                                }
                                            })
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment color="blue">
                        <h5 style={{ textAlign: "center" }}>DATI DESTINAZIONE</h5>
                        <Grid centered>

                            <Grid.Row>
                                <Grid.Column width={5} >
                                    <p>Magazzino</p>
                                    <Select
                                        fluid
                                        onOpen={() => {
                                            loadWharehouse()
                                        }}
                                        value={warehouseName}
                                        onChange={(event, data) => {
                                            setWarehouseName(data.value)
                                            let whSelect = dataWarehouse.filter((x) => x.nomeMagazzino === data.value)[0]
                                            let newOBj = {
                                                "capDestinazione": whSelect.cap,
                                                "viaDestinazione": whSelect.via,
                                                "provinciaDestinazione": whSelect.provincia,
                                                "cittaDestinazione": whSelect.loc,
                                                "civicoDestinazione": whSelect.civico,
                                                "ragioneSocialeDestinazione": whSelect.nomeMagazzino
                                            }
                                            setObjNewOrder((oldState) => {
                                                return { ...oldState, ...newOBj }
                                            })
                                        }}
                                        options={dataSelectWarehouse}
                                    />
                                </Grid.Column>
                                <Grid.Column width={6} >
                                    <p style={{ color: "transparent" }} >Deseleziona Magazzino</p>
                                    <Popup
                                        trigger={
                                            <Button
                                                icon="delete"
                                                negative
                                                onClick={() => {
                                                    let newOBj = {
                                                        "capDestinazione": "",
                                                        "viaDestinazione": "",
                                                        "provinciaDestinazione": "",
                                                        "cittaDestinazione": "",
                                                        "civicoDestinazione": "",
                                                        "ragioneSocialeDestinazione": ""
                                                    }
                                                    setObjNewOrder((oldState) => {
                                                        return { ...oldState, ...newOBj }
                                                    })
                                                    setWarehouseName("")

                                                }}
                                            />
                                        }
                                        content="RIMUOVI MAGAZZINO SELEZIONATO"
                                    />
                                </Grid.Column>

                                <Grid.Column width={5}>
                                    <p>Nazione *</p>
                                    <Select
                                        options={valuesNation}
                                        fluid
                                        value="IT"
                                    />
                                </Grid.Column>

                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <p>Referente</p>
                                    <Input
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "referenteDestinazione": event.target.value }
                                                }
                                            })
                                        }}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <p>Ragione Sociale *</p>

                                    {
                                        !warehouseName
                                        &&
                                        <Input
                                            onChange={(event) => {
                                                setObjNewOrder(preState => {
                                                    return {
                                                        ...preState, ...{ "ragioneSocialeDestinazione": event.target.value }
                                                    }
                                                })
                                            }}
                                            fluid
                                        />
                                    }

                                    {
                                        warehouseName
                                        &&
                                        <Input
                                            value={objNewOrder.ragioneSocialeDestinazione}
                                            disabled
                                            fluid
                                        />
                                    }
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <p>Telefono</p>
                                    <Input
                                        fluid
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "telefonoDestinazione": event.target.value }
                                                }
                                            })
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <p>Email</p>
                                    <Input
                                        fluid
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "emailDestinazione": event.target.value }
                                                }
                                            })
                                        }}

                                    />
                                </Grid.Column>

                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <p>Giorno Chiusura Destinazione 1</p>
                                    <Select
                                        options={valuesDay}
                                        fluid
                                        onChange={(event, data) => {
                                            setObjNewOrder((preState) => {
                                                return {
                                                    ...preState, ...{ "giornoChiusuraDestinazione1": data.value }
                                                }
                                            })
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <p>Periodo Chiusura Destinazione 1</p>
                                    <Select
                                        options={periodDay}
                                        fluid
                                        onChange={(event, data) => {
                                            setObjNewOrder((preState) => {
                                                return {
                                                    ...preState, ...{ "periodoChiusuraDestinazione1": data.value }
                                                }
                                            })
                                        }}
                                    />

                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <p>Giorno Chiusura Destinazione 2</p>
                                    <Select
                                        options={valuesDay}
                                        fluid
                                        onChange={(event, data) => {
                                            setObjNewOrder((preState) => {
                                                return {
                                                    ...preState, ...{ "giornoChiusuraDestinazione2": data.value }
                                                }
                                            })
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <p>Periodo Chiusura Destinazione 2</p>
                                    <Select
                                        options={periodDay}
                                        fluid
                                        onChange={(event, data) => {
                                            setObjNewOrder((preState) => {
                                                return {
                                                    ...preState, ...{ "periodoChiusuraDestinazione2": data.value }
                                                }
                                            })
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment color="blue">
                        <h5 style={{ textAlign: "center" }}>INDIRIZZO DESTINAZIONE</h5>
                        {
                            warehouseName
                            &&
                            <p style={{ textAlign: "center", color: "red" }} ><small>MAGAZZINO SELEZIONATO</small></p>
                        }
                        {
                            !warehouseName
                            &&
                            <Grid>
                                <Grid.Row>
                                    <Egon
                                        setObjSend={setObjNewOrder}
                                        objSend={objNewOrder}
                                        keysMap={{
                                            "citta": "cittaDestinazione",
                                            "pr": "provinciaDestinazione",
                                            "indir": "viaDestinazione",
                                            "cap": "capDestinazione",
                                            "civico": "civicoDestinazione"
                                        }}
                                    />
                                </Grid.Row>
                            </Grid>
                        }

                        {
                            warehouseName
                            &&
                            <Grid centered>
                                <Grid.Row>
                                    <Grid.Column width={3} >
                                        <p>Città *</p>
                                        <Input
                                            fluid
                                            value={objNewOrder.cittaDestinazione}
                                            disabled
                                        />

                                    </Grid.Column>
                                    <Grid.Column width={3} >
                                        <p>Provincia *</p>
                                        <Input
                                            fluid
                                            disabled
                                            value={objNewOrder.provinciaDestinazione}
                                        />

                                    </Grid.Column>
                                    <Grid.Column width={4} >
                                        <p>Via *</p>
                                        <Input
                                            fluid
                                            disabled
                                            value={objNewOrder.viaDestinazione}

                                        />

                                    </Grid.Column>

                                    <Grid.Column width={3} >
                                        <p>Civico *</p>
                                        <Input
                                            fluid
                                            disabled
                                            value={objNewOrder.civicoDestinazione}
                                        />

                                    </Grid.Column>
                                    <Grid.Column width={3} >
                                        <p>Cap *</p>
                                        <Input
                                            fluid
                                            disabled
                                            value={objNewOrder.capDestinazione}
                                        />

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }

                    </Segment>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment color="green">
                        <h5 style={{ textAlign: "center" }}>DATI SPEDIZIONE</h5>
                        <p style={{ textAlign: "center" }}>* Inserire dati relativi a collo di dimensioni maggiori</p>
                        <Grid centered>

                            <Grid.Row>
                                <Grid.Column width={2}>
                                    <p>Numero colli *</p>
                                    <Input
                                        fluid
                                        type="number"
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "numeroColli": Number(event.target.value) }
                                                }
                                            })
                                        }}
                                    />

                                    {
                                        dataBtnPudo?.[indexPudoSelected]
                                        &&
                                        <p style={{ textAlign: "center" }}><small style={{ color: "red" }} >MAX 5</small></p>
                                    }

                                    {
                                        !dataBtnPudo?.[indexPudoSelected]
                                        &&
                                        <p style={{ textAlign: "center" }}><small style={{ color: "red" }} >MAX 30</small></p>
                                    }

                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <p>N° Bancali</p>
                                    <Input
                                        fluid
                                        type="number"
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "numeroBancali": Number(event.target.value) }
                                                }
                                            })
                                        }}
                                    />

                                </Grid.Column>

                                <Grid.Column width={2}>
                                    <p>Peso totale (Kg) *</p>
                                    <Input
                                        fluid
                                        type="number"
                                        input={{ max: dataBtnPudo?.[indexPudoSelected] ? 5 : 100 }}
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "pesoTotale": Number(event.target.value) }
                                                }
                                            })
                                        }}
                                    />

                                    {
                                        dataBtnPudo?.[indexPudoSelected]
                                        &&
                                        <p style={{ textAlign: "center" }}><small style={{ color: "red" }} >MAX 20</small></p>
                                    }

                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <p>Larghezza (Cm) *</p>
                                    <Input
                                        fluid
                                        type="number"
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "larghezza": Number(event.target.value) }
                                                }
                                            })
                                        }}
                                    />
                                    {
                                        dataBtnPudo?.[indexPudoSelected]
                                        &&
                                        <p style={{ textAlign: "center" }}><small style={{ color: "red" }} >MAX 100</small></p>
                                    }

                                </Grid.Column>

                                <Grid.Column width={2}>
                                    <p>Lughezza (Cm) *</p>
                                    <Input
                                        fluid
                                        type="number"
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "lunghezza": Number(event.target.value) }
                                                }
                                            })
                                        }}
                                    />
                                    {
                                        dataBtnPudo?.[indexPudoSelected]
                                        &&
                                        <p style={{ textAlign: "center" }}><small style={{ color: "red" }} >MAX 100</small></p>
                                    }

                                </Grid.Column>

                                <Grid.Column width={2}>
                                    <p>Altezza (Cm) *</p>
                                    <Input
                                        fluid
                                        type="number"
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "altezza": Number(event.target.value) }
                                                }
                                            })
                                        }}
                                    />

                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <p>Tipologia Merce</p>
                                    <Input
                                        fluid
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "tipologiaMerce": event.target.value }
                                                }
                                            })
                                        }}
                                    />

                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <p>Note</p>
                                    <TextArea
                                        style={{ width: "100%", borderRadius: "5px", border: "1px solid lightgrey", padding: "5px" }}
                                        placeholder="MAX 100 CARATTERI"
                                        rows={4}
                                        onChange={(event) => {
                                            setObjNewOrder(preState => {
                                                return {
                                                    ...preState, ...{ "note": event.target.value.length < 101 ? event.target.value : event.target.value.slice(0, 100) }
                                                }
                                            })
                                        }}
                                    />

                                    {
                                        objNewOrder?.note?.length === 100
                                        &&
                                        <p style={{ textAlign: "center" }}><small style={{ color: "red" }} >LIMITE CARATTERI RAGGIUNTI</small></p>
                                    }

                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </Segment>

                </Grid.Column>

            </Grid.Row>
        </Grid>

        <Grid style={{ width: "30%", marginTop: '20px', padding: "10px" }}>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Segment color="violet">
                        <h5 style={{ textAlign: "center" }}>ELENCO PUDO DISPONIBILI</h5>
                        <p style={{ textAlign: "center" }}>* Vengono ricercati in relazione alla sezione "indirizzo richiedente"</p>
                        <Grid centered>
                            <Grid.Row>
                                {
                                    dataBtnPudo.map((x, i) => {
                                        return <Grid.Column key={i + x} width={16}>
                                            <Popup
                                                trigger={
                                                    <Button
                                                        style={{ marginBottom: "5px" }}
                                                        basic={i !== indexPudoSelected}
                                                        primary
                                                        active={i === indexPudoSelected}
                                                        fluid
                                                        content={x.ragSociale}
                                                        onClick={() => setIndexPudoSelected(i)}
                                                    />
                                                }
                                                content={`${x.city}, ${x.address}`}
                                            />
                                        </Grid.Column>
                                    })
                                }

                                {
                                    indexPudoSelected !== null
                                    &&
                                    <Grid.Column width={16}>
                                        <Popup
                                            trigger={
                                                <Button
                                                    style={{ marginBottom: "5px" }}
                                                    fluid
                                                    negative
                                                    basic
                                                    onClick={() => setIndexPudoSelected(null)}
                                                    content={"DESELEZIONA"}
                                                    icon="delete"
                                                    disabled={btnButtonPudoDisabled}
                                                />
                                            }
                                            content={"RIMUOVI PUDO SELEZIONATO"}
                                        />
                                    </Grid.Column>
                                }
                            </Grid.Row>
                        </Grid>

                        <Popup
                            trigger={
                                <Button
                                    style={{ margin: "20px 0px" }}
                                    fluid
                                    positive
                                    onClick={async () => {
                                        try {
                                            const response = await APIpostRetriveServicePoint({
                                                "city": objNewOrder?.cittaRitiro,
                                                "address": objNewOrder?.viaRitiro,
                                                "postalCode": objNewOrder?.capRitiro,
                                            })
                                            setDataBtnPudo(response.data)

                                        } catch (error) {
                                            console.log(error);
                                        }
                                    }}
                                    size="big"
                                    icon="find"
                                    content="RICERCA PUDO"
                                    disabled={btnButtonPudoDisabled}
                                />
                            }
                            content={"RICERCA PUDO (VENGONO UTILIZZATI I DATI DI RITIRO PER LA RICERCA)"}
                        />

                        <Grid centered>
                            <Grid.Row>
                                {
                                    (dataBtnPudo.length > 0 && indexPudoSelected !== null)
                                    &&
                                    <React.Fragment>
                                        <h4>DATI PUDO SELEZIONATO</h4>
                                        {
                                            Object.entries(dataBtnPudo[indexPudoSelected]).map(singleInput => {
                                                return <Grid.Column key={singleInput[0] + singleInput[1]} width={16}>
                                                    <small>{mapLabelPudoSelected[singleInput[0]]}</small>
                                                    <Input disabled value={singleInput[1]} fluid />
                                                </Grid.Column>
                                            })
                                        }
                                    </React.Fragment>
                                }
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>

        <Popup
            trigger={
                <Button
                    icon="send"
                    positive
                    style={{ position: "fixed", bottom: "20px", right: "100px", width: "250px", height: "50px" }}
                    onClick={() => invia()}
                    size="big"
                />
            }
            content="INVIA RICHIESTA"
        />

        {
            (dataBtnPudo.length > 0 && indexPudoSelected !== null)
            &&
            <Popup
                trigger={
                    <Button
                        icon="warehouse"
                        negative
                        style={{ position: "fixed", bottom: "20px", right: "360px", width: "50px", height: "50px" }}
                        onClick={() => setIndexPudoSelected(null)}
                        size="big"
                    />
                }
                content="PUDO SELEZIONATO CLICCA PER RIMUOVERE"
            />
        }
    </div>
}

export default CreateOrder
